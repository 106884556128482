import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { driverType } from 'src/app/core/const/driverType.const';
import { ToasterService } from 'src/app/services/toastr.service';

@Component({
  selector: 'bazaarna-choose-driver',
  templateUrl: './choose-driver.component.html',
  styleUrls: ['./choose-driver.component.scss']
})
export class ChooseDriverComponent {
  driverType = driverType;
  driverValue: string | null = null;

  constructor(
    private _toasterService: ToasterService,
    private dialogRef: MatDialogRef<ChooseDriverComponent>
  ) {}

  onStatusChange(event: MatSelectChange): void {
    this.driverValue = event.value;
  }

  updateStatus(): void {
    const body: {
      deliveryBy: string | null;
    } = {
      deliveryBy: this.driverValue
    };
    if (this.driverValue === null) {
      return this._toasterService.errToastr('Choose Driver at least');
    }
    this.dialogRef.close(body);
  }
}
