import { Component, Inject } from '@angular/core';
import { CouponService } from 'src/app/services/coupon.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { driverType } from 'src/app/core/const/driverType.const';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'bazaarna-add-comission',
  templateUrl: './add-comission.component.html'
})
export class AddComissionComponent {
  submitted = false;
  driverType = driverType;
  paymentFormGroup: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { merchantId?: string },
    private dialogRef: MatDialogRef<AddComissionComponent>,
    private formBuilder: FormBuilder,
    public _couponService: CouponService
  ) {
    this.paymentFormGroup = this.formBuilder.group({
      merchantOwnDriverCommission: [
        '',
        [Validators.required, Validators.max(100)]
      ],
      merchantBazDriverCommission: [
        '',
        [Validators.required, Validators.max(100)]
      ],
      adminCommission: ['', [Validators.required, Validators.max(100)]],
      amount: ['', Validators.required],
      merchantId: [this.data?.merchantId]
    });

    if (!this.data) {
      this.paymentFormGroup.removeControl('amount');
      this.paymentFormGroup.removeControl('merchantId');
    } else {
      this.paymentFormGroup.removeControl('merchantOwnDriverCommission');
      this.paymentFormGroup.removeControl('merchantBazDriverCommission');
      this.paymentFormGroup.removeControl('adminCommission');
    }
  }

  closeDialog(): void {
    this.dialogRef.close(null);
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.paymentFormGroup.valid) {
      this.dialogRef.close(this.paymentFormGroup.value);
    }
  }
}
