/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { AdminUser } from 'src/app/models/adminUser';
import { logoutResponse } from 'src/app/models/logoutResponse';
import { SideBar } from 'src/app/models/sideBar';
import { AuthService } from 'src/app/services/auth.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { environment } from 'src/environments/environment';
import { assetsConst } from '../../const/assets.const';
import { responseStatus } from '../../const/responseStatus.const';
import { SideBarConst } from '../../const/sideBarConst';
import { ModuleConst } from '../../const/moduleConst';
import { userRole } from '../../const/userRole';
import { SocketService } from 'src/app/services/socket.service';

@Component({
  selector: 'bazaarna-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnDestroy {
  @Output() urlOutput = new EventEmitter<any>();

  panelOpenState = false;
  sidenavWidth = 15;

  subscriptionSubject$: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();
  sideBar = SideBarConst;
  moduleConst = ModuleConst;
  assetsConst = assetsConst;
  permissions: any = [];
  constructor(
    private _authService: AuthService,
    private router: Router,
    private _spinnerService: SpinnerService,
    private _socketService: SocketService
  ) {
    this.subscriptionSubject$ = this._authService.profileDetails
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: AdminUser | null) => {
        if (res !== null) {
          this.handleSideBar(res);
        }
      });
  }

  handleSideBar(res: AdminUser): void {
    if (res.role === userRole.SubAdmin) {
      const removeableItems = new Set([this.moduleConst.SubAdmin]); // SideBar Id of items to remove

      this.sideBar = this.sideBar.filter(item => {
        if (removeableItems.has(item.sideBarId)) {
          return false; // Exclude items to be removed
        }

        const current = res.permission?.find(
          el => el.sideBarId === item.sideBarId
        );
        if (current) {
          Object.assign(item, {
            isView: current.isView,
            isAdd: current.isAdd,
            isEdit: current.isEdit,
            isDelete: current.isDelete
          });
        }

        return true; // Keep items that are not removed
      });
    } else {
      this.sideBar.forEach((el: SideBar) => {
        el.isView = el.isAdd = el.isEdit = el.isDelete = true;
      });
    }
  }

  increase(): void {
    this.sidenavWidth = 15;
  }
  decrease(): void {
    this.sidenavWidth = 4;
  }

  fetchEvent(item: SideBar): void {
    if (item.sideBarId === 21) {
      this._authService.logoutAlert().then((res: any) => {
        if (
          Object.prototype.hasOwnProperty.call(res, 'value') &&
          res.isConfirmed === true
        ) {
          this._spinnerService.addToLoader('logout');
          this._authService
            .logout()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
              next: (res: logoutResponse) => {
                if (res.statusCode === responseStatus.SUCCESS) {
                  this._socketService.disconnect();
                  localStorage.removeItem(environment.storageKey);
                  this._spinnerService.removeFromLoader('logout');
                  this.router.navigate(['/auth/login']);
                }
              }
            });
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
    this.destroy$.unsubscribe();
    this._socketService.disconnect();
  }
}
