<div class="rjctn_dv">
  <h1>{{ 'ProvideReason' | translate }}</h1>

  <div>
    <textarea
      class="form_grup txt-area"
      [(ngModel)]="rejectionReason"></textarea>
  </div>

  <div class="error-box">
    <mat-error *ngIf="submitted && showError">{{
      'ProvideReason' | translate
    }}</mat-error>
  </div>

  <button class="btn btn-primary" type="button" (click)="submit()">
    {{ 'Save' | translate }}
  </button>
  <button class="btn btn-primary" type="button" (click)="closeDialog()">
    {{ 'Close' | translate }}
  </button>
</div>
