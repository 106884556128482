import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { responseStatus } from '../const/responseStatus.const';
import { ToasterService } from '../../services/toastr.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertLogoutComponent } from 'src/app/modals/alert-logout/alert-logout.component';
@Injectable()
export class GetInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private _toastrService: ToasterService,
    private dialog: MatDialog
  ) {}
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      tap({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // ToDo
          }
        },
        error: (error: HttpErrorResponse) => {
          switch (error['status']) {
          case responseStatus.BADREQUEST: {
            this._toastrService.errToastr(error['error']['message']);
            break;
          }
          case responseStatus.UNAUTHORIZED: {
            const dialogconst = this.dialog.open(AlertLogoutComponent);
            dialogconst.afterClosed().subscribe((res: any) => {
              if (res) {
                localStorage.clear();
                this.dialog.closeAll();
                this.router.navigate(['/auth/login']);
              }
            });
            break;
          }
          case responseStatus.FORBIDDEN: {
            this._toastrService.errToastr(error['error']['message']);
            localStorage.clear();
            this.router.navigate(['/auth/login']);
            break;
          }
          case responseStatus.REQUESTTIMEOUT: {
            this._toastrService.errToastr(error['error']['message']);
            break;
          }
          case responseStatus.INTERNALSERVERERROR:
            this._toastrService.errToastr(error['error']['message']);
            break;

          case 201:
            this._toastrService.errToastr(error['error']['message']);
            break;

          case 500:
            this._toastrService.errToastr(error['error']['message']);
            break;

          case 501:
            this._toastrService.errToastr(error['error']['message']);
            break;

          case 502:
            this._toastrService.errToastr(error['error']['message']);
            break;

          case 503:
            this._toastrService.errToastr(error['error']['message']);
            break;

          case 504:
            this._toastrService.errToastr(error['error']['message']);
            break;

          case 429:
            this._toastrService.errToastr(error['error']['message']);
            break;
          default:
            this._toastrService.errToastr(
              'Something went wrong... Try Checking your network connections!'
            );
            break;
          }
        }
      })
    );
  }
}
