import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ExportCsvResponse } from '../models/exportCsvResponse';
import { Order } from '../models/order';
import { OrderDetail } from '../models/orderDetail';
import { OrderResponse } from '../models/orderResponse';
import { SubstituteProductReponse } from '../models/substituteProducts';
import { ReturnOrder } from '../models/returnOrder';
import { Products } from '../models/products';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  constructor(private http: HttpClient) {}

  getOrder(query: string): Observable<OrderResponse> {
    return this.http
      .get<{ data: OrderResponse }>(
        `${environment.base_url}admin/getOrders?${query}`
      )
      .pipe(map(res => res.data));
  }

  groupOrders(query: string): Observable<OrderResponse> {
    return this.http
      .get<{ data: OrderResponse }>(
        `${environment.base_url}admin/groupOrders?${query}`
      )
      .pipe(map(res => res.data));
  }

  NumOnly(event: any): unknown {
    const Numpattern = /^([0-9])*$/;
    const resultNum = Numpattern.test(event.key);
    return resultNum;
  }

  getOrderForExchangeReturn(query: string): Observable<OrderResponse> {
    return this.http
      .get<{ data: OrderResponse }>(
        `${environment.base_url}admin/exchangeReturn?${query}`
      )
      .pipe(map(res => res.data));
  }

  getOrderById(id: string): Observable<OrderDetail> {
    return this.http
      .get<{ data: OrderDetail }>(
        `${environment.base_url}admin/getDetailsByOrderId/${id}`
      )
      .pipe(map(res => res.data));
  }

  productsAgainstExchange(id: string): Observable<OrderDetail> {
    return this.http
      .get<{ data: OrderDetail }>(
        `${environment.base_url}admin/productsAgainstExchange/${id}`
      )
      .pipe(map(res => res.data));
  }

  getMerchantStoresForOrder(id: string): Observable<any> {
    return this.http.get(`${environment.base_url}admin/merchantOutlets/${id}`);
  }

  updateOrder(payload: Order): Observable<Order> {
    return this.http
      .put<{ data: Order }>(
        `${environment.base_url}admin/updateOrderStatus`,
        payload
      )
      .pipe(map(res => res.data));
  }

  getOutletByOrderId(id: number): Observable<any> {
    return this.http.get(
      `${environment.base_url}admin/getOutletByOrderId?orderId=${id}`
    );
  }

  actionOnExchangeReturn(payload: Order, id: string): Observable<any> {
    return this.http.put(
      `${environment.base_url}admin/approveRequestStatus/${id}`,
      payload
    );
  }

  exportCsv(query: string): Observable<ExportCsvResponse> {
    return this.http.get<ExportCsvResponse>(
      `${environment.base_url}admin/getOrdersCsvExport?${query}`
    );
  }

  exportExchangeReturnCsv(query: string): Observable<ExportCsvResponse> {
    return this.http.get<ExportCsvResponse>(
      `${environment.base_url}admin/exchangeReturnCSV?${query}`
    );
  }

  getSubstituteProducts(
    id: string,
    query: string
  ): Observable<SubstituteProductReponse> {
    return this.http
      .get<{ data: SubstituteProductReponse }>(
        `${environment.base_url}admin/getSubstitute/${id}?${query}`
      )
      .pipe(map(res => res.data));
  }

  getSubstituteProductsforSearch(
    id: string,
    query: string
  ): Observable<SubstituteProductReponse> {
    return this.http
      .get<{ data: SubstituteProductReponse }>(
        `${environment.base_url}admin/searchSubstituteProducts/${id}?${query}`
      )
      .pipe(map(res => res.data));
  }

  getAvailableDriver(id: string): Observable<any> {
    return this.http.get(
      `${environment.base_url}admin/driversThroughZone/${id}`
    );
  }

  assignOrderToDriver(payload: any, orderId: string): Observable<any> {
    return this.http.post(
      `${environment.base_url}admin/assignDriver/${orderId}`,
      payload
    );
  }

  updateReturnOrder(payload: ReturnOrder): Observable<Order> {
    return this.http
      .put<{ data: Order }>(
        `${environment.base_url}admin/updateReturnOrder`,
        payload
      )
      .pipe(map(res => res.data));
  }

  updateExchangeOrder(payload: ReturnOrder): Observable<Order> {
    return this.http
      .put<{ data: Order }>(
        `${environment.base_url}admin/updateExchangeOrder`,
        payload
      )
      .pipe(map(res => res.data));
  }

  suggestSubstituteToUser(
    id: string,
    data: { suggestedSubstitute: string[] }
  ): Observable<Products> {
    return this.http
      .post<{ data: Products }>(
        `${environment.base_url}admin/sendSubstituteNotification/${id}`,
        data
      )
      .pipe(map(res => res.data));
  }

  rejectOrder(
    paylaod: { rejectionReason: string },
    id: string
  ): Observable<Order> {
    return this.http
      .post<{ data: Order }>(
        `${environment.base_url}admin/rejectOrder/${id}`,
        paylaod
      )
      .pipe(map(res => res.data));
  }
}
