import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'day_diff'
})
export class Day_diffPipe implements PipeTransform {
  currentDate: any = new Date();
  returnString = '';

  constructor() {
    this.currentDate = this.currentDate.toUTCString();
  }

  transform(value: any, args?: any): any {
    const d = new Date(value);

    const minutes =
      (new Date(this.currentDate).getTime() - new Date(d).getTime()) /
      1000 /
      60;

    const hours = Math.floor(minutes / 60);
    const timeResult = this.splitTime(hours);

    if (timeResult.Days >= 1) {
      this.returnString = `${timeResult.Days} ${
        timeResult.Days === 1 ? 'day' : 'days'
      } ago`;
    } else if (timeResult.Days < 1 && timeResult.Hours >= 1) {
      this.returnString = `${timeResult.Hours} ${
        timeResult.Hours === 1 ? 'hour' : 'hours'
      } ago`;
    } else {
      this.returnString = 'a while ago';
    }

    return this.returnString;
  }

  splitTime(numberOfHours: any): any {
    const noOfDays = Math.floor(numberOfHours / 24);
    const remaining = numberOfHours % 24;
    const hour = Math.floor(remaining);
    const minutes = Math.floor(60 * (remaining - hour));
    return { Days: noOfDays,
      Hours: hour,
      Minutes: minutes };
  }
}
