import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { AddBrandComponent } from '../add-brand/add-brand.component';
import { AttributeService } from 'src/app/services/attribute.service';

@Component({
  selector: 'bazaarna-add-attribute',
  templateUrl: './add-attribute.component.html'
})
export class AddAttributeComponent implements OnDestroy {
  submitted = false;
  attributeFormGroup: FormGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private formBuilder: FormBuilder,
    private _spinnerService: SpinnerService,
    private _attributeService: AttributeService,
    private _toasterService: ToasterService,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<AddBrandComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { type: string; label: string } | null
  ) {
    this.attributeFormGroup = this.formBuilder.group({
      label: [data?.label, [Validators.required]],
      value: [data?.type === 'color' ? '#000000' : '', Validators.required],
      parentId: [data?.type === 'color' ? '64d4ae09806dae59508c3f7a' : '64d4a06882daf35a94798dbc']
    });
    this.attributeFormGroup.get('label')?.disable();
  }

  onSubmit(): void {
    this.submitted = true;
    this.attributeFormGroup.get('label')?.enable();
    this.attributeFormGroup.patchValue({
      label: this.attributeFormGroup.get('label')?.value.trim()
    });

    if (this.attributeFormGroup.valid) {
      this._spinnerService.addToLoader('attribute');
      this._attributeService
        .addAttributeValue(this.attributeFormGroup.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this._toasterService.successToastr(
              this.translateService.instant('AttributeValUpdated')
            );
            this._spinnerService.removeFromLoader('attribute');
            this.dialogRef.close(true);
          },
          error: () => {
            this._spinnerService.removeFromLoader('attribute');
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
}
