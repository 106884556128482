import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NgxEchartsModule } from 'ngx-echarts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalsModule } from './modals/modals.module';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { AfterLoginComponent } from './layout/after-login/after-login.component';
import { SidebarComponent } from './core/components/sidebar/sidebar.component';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { SetInterceptorService } from './core/interceptor/set-interceptor.service';
import { GetInterceptorService } from './core/interceptor/get-interceptor.service';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { InitialService } from './services/initial.service';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { PipesModule } from './shared/pipes/pipes.module';
import { AfterLoginShared } from './shared/after-login.shared.module';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

type FactoryFunction = () => void;

export function initialServiceFactory(
  InitialService: InitialService
): FactoryFunction {
  return () => InitialService.setUpGoogleScript();
}

@NgModule({
  declarations: [AppComponent, AfterLoginComponent, SidebarComponent],
  exports: [TranslateModule],
  providers: [
    CookieService,
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: initialServiceFactory,
      deps: [InitialService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GetInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  imports: [
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    MatMenuModule,
    MatSidenavModule,
    MatListModule,
    MatTooltipModule,
    MatExpansionModule,
    NgxUiLoaderModule,
    ModalsModule,
    GooglePlaceModule,
    PipesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AfterLoginShared
  ]
})
export class AppModule {}
