import { Injectable, NgModule } from '@angular/core';
import { CustomCurrencyPipe } from './custom-currency.pipe';
import { Day_diffPipe } from './day_diff.pipe';
import { CommonModule } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
@NgModule({
  imports: [CommonModule],
  declarations: [CustomCurrencyPipe, Day_diffPipe],
  exports: [CustomCurrencyPipe, Day_diffPipe]
})
export class PipesModule {}
