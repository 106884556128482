import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AdminNotifications } from '../models/adminNotifications';
import { AddNotification, GetNotifications } from '../models/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private http: HttpClient) {}

  getAdminNotifications(query: string): Observable<AdminNotifications> {
    return this.http
      .get<{ data: AdminNotifications }>(
        `${environment.base_url}admin/getNotifications?${query}`
      )
      .pipe(map(res => res.data));
  }

  getNotificationWidget(): Observable<AdminNotifications> {
    return this.http
      .get<{ data: AdminNotifications }>(
        `${environment.base_url}admin/getNotificationWidget`
      )
      .pipe(map(res => res.data));
  }

  getNotificationList(query: string): Observable<GetNotifications> {
    return this.http
      .get<GetNotifications>(
        `${environment.base_url}admin/getbroadcastNotification?${query}`
      )
      .pipe(map(res => res));
  }

  addNotification(payload: unknown): Observable<AddNotification> {
    return this.http
      .post<AddNotification>(
        `${environment.base_url}admin/broadcastNotification`,
        payload
      )
      .pipe(map(res => res));
  }
}
