import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { assetsConst } from './core/const/assets.const';

@Component({
  selector: 'bazaarna-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Bazaarna';
  assetsConst = assetsConst;

  constructor(translate: TranslateService) {
    if (!localStorage.getItem(environment.storageKey)) {
      localStorage.clear();
      // Redirect to login page or handle authentication flow
    }
    translate.setDefaultLang('en');
    translate.use('en');
  }
}
