import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { Template } from 'src/app/models/template';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TemplateService } from 'src/app/services/template.service';
import { ToasterService } from 'src/app/services/toastr.service';

@Component({
  selector: 'bazaarna-edit-template',
  templateUrl: './edit-template.component.html'
})
export class EditTemplateComponent implements OnInit, OnDestroy {
  content = '';
  config = {};
  submitted = false;
  templateFormGroup: FormGroup;
  type: string | null = null;
  templateId: string | null = null;
  previousPage: string | number | null = 1;
  destroy$: Subject<boolean> = new Subject<boolean>();
  allTempTypes = [
    { key: 'ONBOARDING',
      value: 1 },
    { key: 'FORGOT PASSWORD',
      value: 2 },
    { key: 'ORDER PLACED',
      value: 3 }
  ];
  constructor(
    private formBuilder: FormBuilder,
    private _spinnerService: SpinnerService,
    private _templateService: TemplateService,
    private _toasterService: ToasterService,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<EditTemplateComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { type: string; TemplateData: Template } | null
  ) {
    this.templateFormGroup = this.formBuilder.group({
      content: ['', Validators.required],
      type: ['', Validators.required]
    });

    if (this.data !== null) {
      this.type = this.data.type;
      this.templateId =
        this.data.TemplateData !== null &&
        this.data.TemplateData._id !== undefined ? this.data.TemplateData._id : null;
    }
  }

  ngOnInit(): void {
    if (this.templateId !== null) {
      this.getTemplateDetail();
    }
  }

  getTemplateDetail(): void {
    this._templateService
      .getTemplateById(this.templateId !== null ? this.templateId : '')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: Template) => {
          this.templateFormGroup.patchValue(res);
        }
      });
  }

  onSubmit(): void {
    this.submitted = true;

    this.templateFormGroup.patchValue({
      content: this.templateFormGroup.get('content')?.value.trim()
    });
    if (this.templateFormGroup.valid) {
      this._spinnerService.addToLoader('template');
      (this.type === 'add' ? this._templateService.addTemplate(this.templateFormGroup.value) : this._templateService.updateTemplate(
        this.templateFormGroup.value,
        this.templateId !== null ? this.templateId : ''
      )
      )
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.type === 'add' ? this._toasterService.successToastr(
              this.translateService.instant('TemplateAdded')
            ) : this._toasterService.successToastr(
              this.translateService.instant('TemplateUpdated')
            );
            this._spinnerService.removeFromLoader('template');
            this.dialogRef.close(true);
          },
          error: () => {
            this._spinnerService.removeFromLoader('template');
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
}
