import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'bazaarna-alert-logout',
  templateUrl: './alert-logout.component.html',
  styleUrls: ['./alert-logout.component.scss']
})
export class AlertLogoutComponent {
  constructor(private dialogRef: MatDialogRef<AlertLogoutComponent>) {}

  closeDialog(): void {
    this.dialogRef.close(true);
  }
}
