import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Attributes } from '../models/attribute';
import { AttributeDetailResponse } from '../models/attributeDetailResponse';

@Injectable({
  providedIn: 'root'
})
export class AttributeService {
  attributeData: Attributes = {
    type: '',
    label: '',
    value: '',
    parentId: '',
    _id: null
  };

  constructor(private http: HttpClient) {}

  getAttributes(): Observable<Attributes[]> {
    return this.http
      .get<{ data: { attribute: Attributes[] } }>(
        `${environment.base_url}admin/getAttributeType`
      )
      .pipe(map(res => res.data.attribute));
  }

  getAttributeDetail(id: string): Observable<AttributeDetailResponse> {
    return this.http
      .get<{ data: AttributeDetailResponse[] }>(
        `${environment.base_url}admin/getAssociatedAttributeValue/${id}`
      )
      .pipe(map(res => res.data[0]));
  }

  updateAttributeValues(
    payload: AttributeDetailResponse,
    id: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    return this.http.put(
      `${environment.base_url}admin/updateAssociatedAttributeValue/${id}`,
      payload
    );
  }

  addAttributeValue(
    payload: Attributes
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    return this.http.post(
      `${environment.base_url}admin/addAttributeValue`,
      payload
    );
  }
}
