import { Component, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OutletData } from 'src/app/models/OutletData';
import { Router } from '@angular/router';
import { Merchant } from 'src/app/models/merchant';

@Component({
  selector: 'bazaarna-show-outlet-listing',
  templateUrl: './show-outlet-listing.component.html'
})
export class ShowOutletListingComponent {
  dataSource: Array<OutletData> = [];
  displayedColumns = ['#', 'mercahntDetail', 'outlets', 'action'];
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: { outletData: Array<OutletData> },
    private dialogRef: MatDialogRef<ShowOutletListingComponent>
  ) {
    this.dataSource = data.outletData;
  }

  routeToMerchantOutlet(merchant: Merchant): void {
    this.router.navigateByUrl(
      `/merchant/merchant_outlets/${merchant._id}?page=1&previousIndex=0&merchantName=${merchant.name}&status=pending`
    );
    this.dialogRef.close();
  }
}
