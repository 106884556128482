import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { assetsConst } from 'src/app/core/const/assets.const';
import { staticText } from 'src/app/core/const/staticText.const';
import { paginationOutput } from 'src/app/models/paginationOutput';
import {
  SubstituteProduct,
  SubstituteProductReponse
} from 'src/app/models/substituteProducts';
import { OrderService } from 'src/app/services/order.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'bazaarna-susbtitute-products',
  templateUrl: './susbtitute-products.component.html',
  styleUrls: ['./susbtitute-products.component.scss']
})
export class SusbtituteProductsComponent implements OnDestroy {
  dataSource: Array<SubstituteProduct> = [];
  displayedColumns = [
    '#',
    'image',
    'name',
    'brand',
    'category',
    'price',
    'action'
  ];

  displayedColumnsForSearch = ['image', 'name', 'action'];
  assetsConst = assetsConst;
  staticText = staticText;
  destroy$: Subject<boolean> = new Subject<boolean>();
  recordsLength = 1;
  currentPage: number | null = null;
  pageSize = 10;
  search = '';
  dataSourceSearch: Array<SubstituteProduct> = [];
  suggestedSubstitute: Array<string> = [];
  showSubstituteSeaarch = false;
  showSubstitute = false;

  constructor(
    private _spinnerService: SpinnerService,
    private _orderService: OrderService,
    private dialogRef: MatDialogRef<SusbtituteProductsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string | {id: string, status: number}
  ) {
    this.getSubstituteProducts();

    if (typeof this.data !== 'string') {
      this.displayedColumns.pop();
    }

  }

  getSubstituteProducts(): void {
    const query = `page=${this.currentPage}&size=${this.pageSize}`;

    this._spinnerService.addToLoader('getSubstituteProduct');
    this._orderService
      .getSubstituteProducts(typeof this.data === 'string' ? this.data : this.data.id, query)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: SubstituteProductReponse) => {
          this._spinnerService.removeFromLoader('getSubstituteProduct');
          this.dataSource = typeof this.data === 'string' ? res.substitute : res.alreadySuggestedSubstitute;
          if (this.dataSource.length) {
            this.showSubstituteSeaarch = true;
            this.showSubstitute = false;
          }
          if (!this.dataSource.length) {
            this.showSubstituteSeaarch = false;
            this.showSubstitute = true;
          }
          this.suggestedSubstitute = this.dataSource.map(el => el._id);
          this.dataSource.forEach(el => {
            el.isSelected = false;
          });
          this.recordsLength = res.count;
        },
        error: () => {
          this._spinnerService.removeFromLoader('getSubstituteProduct');
        }
      });
  }

  fetchSearchOutput(event: string | null): void {
    if (event && event !== null) {
      this.search = event;
      this.getSubstituteSearchProducts();
    } else {
      this.search = '';
      this.getSubstituteSearchProducts();
    }
  }

  getSubstituteSearchProducts(): void {
    const query = `page=${this.currentPage}&size=${this.pageSize}&search=${this.search}`;

    this._spinnerService.addToLoader('getSubstituteProduct');
    this._orderService
      .getSubstituteProductsforSearch(typeof this.data === 'string' ? this.data : this.data.id, query)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: SubstituteProductReponse) => {
          if (res) {
            this._spinnerService.removeFromLoader('getSubstituteProduct');
            res.substitute.forEach(el => {
              el.isSelected = false;
            });
            this.dataSourceSearch = res.substitute;
            if (this.dataSourceSearch) {
              this.showSubstituteSeaarch = true;
              this.showSubstitute = false;
            }
            if (!this.dataSourceSearch.length) {
              this.showSubstituteSeaarch = false;
              this.showSubstitute = true;
            }
            this.recordsLength = res.count;
          }
        },
        error: () => {
          this._spinnerService.removeFromLoader('getSubstituteProduct');
        }
      });
  }

  fetchPaginationOutput(event: paginationOutput): void {
    this.currentPage = event.currentPage;
    this.pageSize = event.pageSize;
    this.getSubstituteProducts();
  }

  fetchPaginationOutputForSearch(event: paginationOutput): void {
    this.currentPage = event.currentPage;
    this.pageSize = event.pageSize;
    this.getSubstituteSearchProducts();
  }

  onDeleteProduct(i: number, element: SubstituteProduct): void {
    this.suggestedSubstitute = this.suggestedSubstitute.filter(
      item => item !== element._id
    );
    element.isSelected = false;
    this.dataSource = this.dataSource.filter(item => item._id !== element._id);
  }

  onAddProduct(product: SubstituteProduct): void {
    product.isSelected = true;
    this.dataSource.push(product);
    this.dataSource = new Array(...this.dataSource);

    this.suggestedSubstitute.push(product._id);
  }

  suggestSubstitute(): void {
    const body = {
      suggestedSubstitute: this.suggestedSubstitute
    };
    this._spinnerService.addToLoader('suggestSubstitute');
    this._orderService
      .suggestSubstituteToUser(typeof this.data === 'string' ? this.data : this.data.id, body)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this._spinnerService.removeFromLoader('suggestSubstitute');
          this.dialogRef.close(true);
        },
        error: () => {
          this._spinnerService.removeFromLoader('suggestSubstitute');
          this.dialogRef.close(false);
        }
      });
  }

  isSubstituteSuggested(): boolean {
    let isVisible = true;

    if (typeof this.data === 'string') {
      isVisible = true;
    } else {
      isVisible = false;
    }

    return isVisible;
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
}
