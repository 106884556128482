<div class="modal_title">
  <h1>{{ 'AddNotification' | translate }}</h1>
</div>
<div class="tables">
  <div class="form_feilds" [formGroup]="addNotificationForm">
    <div class="form_grup w_100">
      <mat-label
        >{{ 'NotificationSend' | translate }} <span class="astrick"></span
      ></mat-label>
      <mat-form-field floatLabel="never">
        <mat-select
          placeholder="{{ 'NotificationSend' | translate }}"
          formControlName="notificationSendTo">
          <mat-option value="1">{{ 'All' | translate }}</mat-option>
          <mat-option value="2">{{ 'Customer' | translate }}</mat-option>
          <mat-option value="3">{{ 'Driver' | translate }}</mat-option>
          <mat-option value="4">{{ 'Merchant' | translate }}</mat-option>
          <mat-option value="5">{{ 'Dispatcher' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div>
        <mat-error
          *ngIf="
            submitted &&
            addNotificationForm.get('notificationSendTo')?.hasError('required')
          ">
          {{ 'NotificationSend' | translate }}
          {{ 'IsRequired' | translate }}
        </mat-error>
      </div>
    </div>
    <div class="form_grup w_100">
      <mat-label
        >{{ 'NotificationType' | translate }} <span class="astrick"></span
      ></mat-label>
      <mat-form-field appearance="fill">
        <mat-select
          placeholder="{{ 'NotificationType' | translate }}"
          formControlName="notificationType">
          <mat-option value="1">{{ 'Email' | translate }}</mat-option>
          <mat-option value="2">{{
            'PushNotification' | translate
          }}</mat-option>
          <mat-option value="3">{{ 'Both' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div>
        <mat-error
          *ngIf="
            submitted &&
            addNotificationForm.get('notificationType')?.hasError('required')
          ">
          {{ 'NotificationType' | translate }}
          {{ 'IsRequired' | translate }}
        </mat-error>
      </div>
    </div>
    <div class="form_grup w_100">
      <mat-label
        >{{ 'NotificationTitle' | translate }} <span class="astrick"></span
      ></mat-label>
      <mat-form-field appearance="fill">
        <input
          matInput
          placeholder="{{ 'Title' | translate }}"
          class="form_control"
          formControlName="title" />
      </mat-form-field>
      <div>
        <mat-error
          *ngIf="
            submitted && addNotificationForm.get('title')?.hasError('required')
          ">
          {{ 'Title' | translate }}
          {{ 'IsRequired' | translate }}
        </mat-error>
        <mat-error
          *ngIf="
            submitted && addNotificationForm.get('title')?.hasError('maxlength')
          ">
          {{ 'Title' | translate }}
          {{ '100CharLength' | translate }}
        </mat-error>
      </div>
    </div>
    <div class="form_grup w_100">
      <mat-label
        >{{ 'NotificationMessage' | translate }} <span class="astrick"></span
      ></mat-label>
      <mat-form-field appearance="fill">
        <textarea
          matInput
          placeholder="{{ 'Message' | translate }}"
          class="form_control"
          formControlName="message"></textarea>
      </mat-form-field>
      <div>
        <mat-error
          *ngIf="
            submitted &&
            addNotificationForm.get('message')?.hasError('required')
          ">
          {{ 'Message' | translate }}
          {{ 'IsRequired' | translate }}
        </mat-error>
        <mat-error
          *ngIf="
            submitted &&
            addNotificationForm.get('message')?.hasError('maxlength')
          ">
          {{ 'Message' | translate }}
          {{ '500CharLength' | translate }}
        </mat-error>
      </div>
    </div>
  </div>
  <div class="filter_lft">
    <button class="btn btn-primary" type="button" (click)="addNotification()">
      {{ 'Submit' | translate }}
    </button>
  </div>
</div>
