<mat-sidenav-container fullscreen>
  <mat-sidenav
    #sidenav
    mode="side"
    class="example-sidenav"
    [ngStyle]="{ 'width.em': sidenavWidth }"
    opened="true">
    <bazaarna-sidebar></bazaarna-sidebar>
  </mat-sidenav>
  <div class="example-sidenav-content">
    <div class="top_header">
      <div class="toggle" (click)="sidenav.toggle()">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="top_header_ryt">
        <i
          class="fa fa-bell"
          aria-hidden="true"
          [matMenuTriggerFor]="menu1"></i>
        <mat-menu
          #menu1="matMenu"
          class="dropbox"
          xPosition="before"
          yPosition="below">
          <ul>
            <h4>{{ 'Notifications' | translate }}</h4>
            <ul>
              <ng-container *ngFor="let item of notificationsData">
                <li class="ntf-list">
                  <figure>
                    <img [src]="assetsConst.bellIcon" alt="notification" />
                  </figure>
                  <div class="ntf_dt">
                    <h3>{{ item.title }}</h3>
                    <p>{{ item.message }}</p>
                  </div>
                  <p class="ntf_tym">
                    {{ item.createdAt | day_diff : item.createdAt }}
                  </p>
                </li>
              </ng-container>
            </ul>
            <div
              class="submit"
              *ngIf="notificationsCount && notificationsCount > 5">
              <button
                type="button"
                class="see_link"
                routerLink="/received_notification">
                {{ 'SeeAll' | translate }}
              </button>
            </div>
          </ul>
        </mat-menu>
        <figure mat-button [matMenuTriggerFor]="menu">
          <img
            [src]="
              profileDetails && profileDetails.image
                ? profileDetails.image
                : assetsConst.user
            "
            alt="profile" />
        </figure>
        <mat-menu
          #menu="matMenu"
          class="dropbox"
          xPosition="before"
          yPosition="below">
          <ul class="hd_menu">
            <li routerLink="/profile/profile">
              <figure>
                <img [src]="assetsConst.profileSvg" alt="profile" />
              </figure>
              {{ 'MyProfile' | translate }}
            </li>
            <li routerLink="/password/change-paswd">
              <figure>
                <img [src]="assetsConst.changePasswordSvg" alt="profile" />
              </figure>
              {{ 'ChangePassword' | translate }}
            </li>
            <li (click)="logout()">
              <figure>
                <img [src]="assetsConst.logoutSvg" alt="profile" />
              </figure>
              {{ 'Logout' | translate }}
            </li>
          </ul>
        </mat-menu>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
  <div class="top_header_ryt end_pop">
    <figure mat-icon-button [matMenuTriggerFor]="menu2">
      <img [src]="assetsConst.order" alt="" />
    </figure>
    <mat-menu class="end_pop_drop" #menu2="matMenu">
      <div class="not_header">
        <h4 style="text-align: center">{{ 'OrderDetails' | translate }}</h4>
      </div>
      <ng-container
        *ngIf="notificationWidget && notificationWidget.length; else noData">
        <ul *ngFor="let item of notificationWidget" class="hd_menu">
          <li style="cursor: pointer" routerLink="/orders/list/0/1">
            <div class="ntf_dt">
              <p>{{ 'orderTempl' | translate }}</p>
              <div class="id_dt">
                <span class="nt_typ"
                  >{{ 'OrderID' | translate }}: {{ item.orderId }}</span
                >
                <span class="dt_ne">
                  {{ item.createdAt | date : 'short' }}</span
                >
              </div>
            </div>
          </li>
        </ul>
      </ng-container>
      <ng-template #noData>
        <bazaarna-nodatafound></bazaarna-nodatafound>
      </ng-template>
    </mat-menu>
  </div>
</mat-sidenav-container>
