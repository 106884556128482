import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TemplateListRespone } from '../models/templateListResponse';
import { Template } from '../models/template';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  constructor(private http: HttpClient) {}

  getTemplateListing(queryString: string): Observable<TemplateListRespone> {
    return this.http
      .get<{ data: TemplateListRespone }>(
        `${environment.base_url}admin/getCustomTemplate/?${queryString}`
      )
      .pipe(map(res => res.data));
  }

  getTemplateById(brandId: string): Observable<Template> {
    return this.http
      .get<{ data: Template }>(
        `${environment.base_url}admin/getCustomTemplateById/${brandId}`
      )
      .pipe(map(res => res.data));
  }

  addTemplate(payload: Template): Observable<Template> {
    return this.http
      .post<{ data: Template }>(
        `${environment.base_url}admin/addCustomTemplate`,
        payload
      )
      .pipe(map(res => res.data));
  }

  updateTemplate(payload: Template, id: string): Observable<Template> {
    return this.http
      .put<{ data: Template }>(
        `${environment.base_url}admin/updateCustomTemplate/${id}`,
        payload
      )
      .pipe(map(res => res.data));
  }

  deleteTemplate(id: string): Observable<number> {
    return this.http
      .delete<{ statusCode: number }>(
        `${environment.base_url}admin/deleteCustomTemplate/${id}`
      )
      .pipe(map(res => res.statusCode));
  }
}
