import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Coupon } from '../models/coupon';
import { CouponListRespose } from '../models/couponListResponse';
import { ExportCsvResponse } from '../models/exportCsvResponse';

@Injectable({
  providedIn: 'root'
})
export class CouponService {
  constructor(private http: HttpClient) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  NumOnly(event: any): void {
    const inputText = event.target.value.replace(/[^0-9.]/g, '');
    const decimalIndex = inputText.indexOf('.');

    if (decimalIndex !== -1) {
      const integerPart = inputText.slice(0, decimalIndex); // Extract the integer part
      const decimalPart = inputText.slice(decimalIndex + 1); // Extract the decimal part
      event.target.value = `${integerPart}.${decimalPart.slice(0, 2)}`; // Limit decimal upto 2 digits
    } else {
      event.target.value = inputText.slice(0, 10);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  preventPaste(event: any): void {
    event.preventDefault();
  }

  getCouponListing(queryString: string): Observable<CouponListRespose> {
    return this.http
      .get<{ data: CouponListRespose }>(
        `${environment.base_url}admin/getCoupon?${queryString}`
      )
      .pipe(map(res => res.data));
  }

  getCouponById(couponId: string): Observable<Coupon> {
    return this.http
      .get<{ data: Coupon }>(
        `${environment.base_url}admin/getCouponById/${couponId}`
      )
      .pipe(map(res => res.data));
  }

  addCoupon(payload: Coupon): Observable<Coupon> {
    return this.http
      .post<{ data: Coupon }>(`${environment.base_url}admin/addCoupon`, payload)
      .pipe(map(res => res.data));
  }

  updateCoupon(payload: Coupon, id: string): Observable<Coupon> {
    return this.http
      .put<{ data: Coupon }>(
        `${environment.base_url}admin/updateCoupon/${id}`,
        payload
      )
      .pipe(map(res => res.data));
  }

  updateCouponStatus(
    payload: { isBlocked: boolean },
    id: string
  ): Observable<Coupon> {
    return this.http
      .put<{ data: Coupon }>(
        `${environment.base_url}admin/updateCoupon/${id}`,
        payload
      )
      .pipe(map(res => res.data));
  }

  deleteCoupon(id: string): Observable<number> {
    return this.http
      .delete<{ statusCode: number }>(
        `${environment.base_url}admin/deleteCoupon/${id}`
      )
      .pipe(map(res => res.statusCode));
  }

  exportCsv(query: string): Observable<ExportCsvResponse> {
    return this.http.get<ExportCsvResponse>(
      `${environment.base_url}admin/getCouponExport?${query}`
    );
  }
}
