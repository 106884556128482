import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { EditTemplateComponent } from './edit-template/edit-template.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { AddNotificationComponent } from './add-notification/add-notification.component';
import { TranslateModule } from '@ngx-translate/core';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { RejectionReasonComponent } from './rejection-reason/rejection-reason.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AcceptPopupComponent } from './accept-popup/accept-popup.component';
import { MatTableModule } from '@angular/material/table';
import { AddBrandComponent } from './add-brand/add-brand.component';
import { SusbtituteProductsComponent } from './susbtitute-products/susbtitute-products.component';
import { AfterLoginShared } from '../shared/after-login.shared.module';
import { AssignDriverComponent } from './assign-driver/assign-driver.component';
import { AddComissionComponent } from './add-comission/add-comission.component';
import { ShowOutletListingComponent } from './show-outlet-listing/show-outlet-listing.component';
import { RouterModule } from '@angular/router';
import { OrderInvoiceComponent } from './order-invoice/order-invoice.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipesModule } from '../shared/pipes/pipes.module';
import { ChooseDriverComponent } from './choose-driver/choose-driver.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { AddAttributeComponent } from './add-attribute/add-attribute.component';
import { SearchModule } from '../shared/components/search/search.module';
import { PaginatorModule } from '../shared/components/paginator/paginator.module';
import { AlertLogoutComponent } from './alert-logout/alert-logout.component';

@NgModule({
  declarations: [
    EditTemplateComponent,
    AddNotificationComponent,
    ImageViewerComponent,
    RejectionReasonComponent,
    AcceptPopupComponent,
    AddBrandComponent,
    SusbtituteProductsComponent,
    AssignDriverComponent,
    AddComissionComponent,
    ShowOutletListingComponent,
    OrderInvoiceComponent,
    ChooseDriverComponent,
    AddAttributeComponent,
    AlertLogoutComponent
  ],
  exports: [OrderInvoiceComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    CKEditorModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    RouterModule,
    AfterLoginShared,
    MatTooltipModule,
    PipesModule,
    MatDialogModule,
    ClipboardModule,
    SearchModule,
    PaginatorModule
  ]
})
export class ModalsModule {}
