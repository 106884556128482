<div class="modal_title">
  <h1>
    {{
      (type === 'add' ? 'AddTemplateContent' : 'EditTemplateContent')
        | translate
    }}
  </h1>
</div>

<form [formGroup]="templateFormGroup">
  <div class="form_feilds">
    <div class="form_grup w_100">
      <mat-label
        >{{ 'TemplateType' | translate }} <span class="astrick"></span
      ></mat-label>
      <mat-form-field appearance="fill">
        <mat-select
          placeholder="{{ 'SelectTemplate' | translate }}"
          formControlName="type">
          <mat-option *ngFor="let data of allTempTypes" [value]="data.value">{{
            data.key
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error
        *ngIf="
          submitted && templateFormGroup.controls['type'].hasError('required')
        ">
        {{ 'TemplateType' | translate }}
        {{ 'IsRequired' | translate }}
      </mat-error>
    </div>
  </div>
  <mat-label>{{ 'TemplateContent' | translate }}</mat-label>
  <ckeditor
    formControlName="content"
    [data]="content"
    [config]="config"></ckeditor>
  <mat-error
    *ngIf="
      submitted && templateFormGroup.controls['content'].hasError('required')
    ">
    {{ 'TemplateContent' | translate }}
    {{ 'IsRequired' | translate }}
  </mat-error>
  <button type="button" (click)="onSubmit()" class="btn btn-primary mt_20">
    {{ (type === 'Submit' ? 'Add' : 'Update') | translate }}
  </button>
</form>
