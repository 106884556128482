import { SideBar } from 'src/app/models/sideBar';
import { ModuleConst } from './moduleConst';
import { assetsConst } from './assets.const';

export const SideBarConst: Array<SideBar> = [
  {
    text: 'Dashboard',
    path: '/dashboard',
    icon: assetsConst.dash,
    isSubMenu: false,
    sideBarId: ModuleConst.Dashboard
  },
  {
    text: 'Users',
    path: '/manage_user',
    icon: assetsConst.profileSvg,
    isSubMenu: false,
    sideBarId: ModuleConst.Users
  },
  {
    text: 'Dispatcher Managers',
    path: '/dispatcher',
    icon: assetsConst.dispacther,
    isSubMenu: false,
    sideBarId: ModuleConst.DispatcherManagers
  },
  {
    text: 'Orders',
    icon: assetsConst.manageOrder,
    isSubMenu: true,
    sideBarId: ModuleConst.Orders,
    path: '/orders',
    subMenuItems: [
      {
        text: 'Orders',
        path: '/orders',
        icon: assetsConst.order3,
        isSubMenu: false,
        sideBarId: 601
      },
      {
        text: 'Exchange/Return',
        path: '/orders/return/0/1',
        icon: assetsConst.order2,
        isSubMenu: false,
        sideBarId: 602
      }
    ]
  },
  {
    text: 'Merchants',
    path: '/merchant',
    icon: assetsConst.merchant,
    isSubMenu: false,
    sideBarId: ModuleConst.Merchants
  },
  {
    text: 'Reports',
    icon: assetsConst.reports,
    isSubMenu: true,
    sideBarId: ModuleConst.Reports,
    path: '/reports',
    subMenuItems: [
      {
        text: 'Merchant Reports',
        path: '/reports',
        icon: assetsConst.merchtnReport,
        isSubMenu: false,
        sideBarId: 801
      },
      {
        text: 'Earning Reports',
        path: '/reports/earning',
        icon: assetsConst.earn,
        isSubMenu: false,
        sideBarId: 802
      },
      {
        text: 'Sales Reports',
        path: '/reports/sales',
        icon: assetsConst.sales,
        isSubMenu: false,
        sideBarId: 803
      }
    ]
  },
  {
    text: 'Categories',
    path: '/categories',
    icon: assetsConst.categroy,
    isSubMenu: false,
    sideBarId: ModuleConst.Categories
  },
  {
    text: 'Brand Management',
    path: '/brand',
    icon: assetsConst.payment,
    isSubMenu: false,
    sideBarId: ModuleConst.BrandManagement
  },
  {
    text: 'Payments',
    path: '/payment',
    icon: assetsConst.packagingPrice,
    isSubMenu: false,
    sideBarId: ModuleConst.Payments
  },
  {
    text: 'Promo Codes',
    path: '/coupon',
    icon: assetsConst.coupon,
    isSubMenu: false,
    sideBarId: ModuleConst.Coupon
  },
  {
    text: 'Gift cards',
    path: '/gift_card',
    icon: assetsConst.giftCard,
    isSubMenu: false,
    sideBarId: ModuleConst.GiftCards
  },
  {
    text: 'AD Banner ',
    path: '/banners',
    icon: assetsConst.ad,
    isSubMenu: false,
    sideBarId: ModuleConst.AdBanner
  },
  {
    text: 'Attributes ',
    path: '/attribute',
    icon: assetsConst.product,
    isSubMenu: false,
    sideBarId: ModuleConst.Attribute
  },
  {
    text: 'Notifications',
    path: 'notification/notification/1',
    icon: assetsConst.bellIcon,
    isSubMenu: false,
    sideBarId: ModuleConst.Notificaton
  },
  {
    text: 'Sub Admin',
    path: '/subadmin',
    icon: assetsConst.subadmin,
    isSubMenu: false,
    sideBarId: ModuleConst.SubAdmin
  },
  {
    text: 'Customer Support',
    path: '/support/chat',
    icon: assetsConst.customerSupport,
    isSubMenu: false,
    sideBarId: ModuleConst.CustomerSupport
  },

  {
    text: 'CMS Management',
    path: '/cms',
    icon: assetsConst.cms,
    isSubMenu: false,
    sideBarId: ModuleConst.CmsManagement
  },
  {
    text: 'Settings',
    path: '/settings',
    icon: assetsConst.setting,
    isSubMenu: false,
    sideBarId: ModuleConst.Setting
  },
  {
    text: 'Logout',
    icon: assetsConst.logoutSvg,
    isSubMenu: false,
    sideBarId: ModuleConst.Logout
  }
];
