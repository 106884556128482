import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { driverType } from 'src/app/core/const/driverType.const';
import { Order } from 'src/app/models/order';
import { OrderService } from 'src/app/services/order.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'bazaarna-accept-popup',
  templateUrl: './accept-popup.component.html',
  styleUrls: ['./accept-popup.component.scss']
})
export class AcceptPopupComponent {
  displayedColumns = ['#', 'Name', 'assign'];
  dataSource = [];
  driverType = driverType;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private _orderService: OrderService,
    private _spinnerService: SpinnerService,
    private dialogRef: MatDialogRef<AcceptPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Order
  ) {
    this.getMerchantOutlets();
  }

  getMerchantOutlets(): void {
    this._spinnerService.addToLoader('getMerchantOutlets');
    this._orderService
      .getOutletByOrderId(this.data.orderId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        next: (res: any) => {
          this._spinnerService.removeFromLoader('getMerchantOutlets');
          this.dataSource = res.data;
        },
        error: () => {
          this._spinnerService.removeFromLoader('getMerchantOutlets');
        }
      });
  }

  assignStore(elementId: string): void {
    const body = {
      status: true,
      outletId: elementId
    };
    this.dialogRef.close(body);
  }
}
