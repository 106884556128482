export const assetsConst = {
  spinner: 'assets/images/0998.gif',
  ad: 'assets/images/ad.svg',
  addSvg: 'assets/images/add.svg',
  camera: 'assets/images/camera.png',
  category: 'assets/images/category_n.svg',
  categroy: 'assets/images/category.svg',
  cms: 'assets/images/cms.svg',
  coupon: 'assets/images/coupon.svg',
  customerSupport: 'assets/images/customer_support.svg',
  dash: 'assets/images/dash.svg',
  dispacther: 'assets/images/dispatcher_manager.svg',
  driver: 'assets/images/driver_n.svg',
  driver1: 'assets/images/driver.svg',
  earn: 'assets/images/earn_report.svg',
  giftCard: 'assets/images/gift_card.svg',
  logo: 'assets/images/logo.svg',
  logo2: 'assets/images/logo2.svg',
  logoutSvg: 'assets/images/logout.svg',
  merchant: 'assets/images/manage_merchant.svg',
  manageOrder: 'assets/images/manage_order.svg',
  markUp: 'assets/images/markup_price.svg',
  merchtnReport: 'assets/images/merchant_report.svg',
  newUser: 'assets/images/new_users.svg',
  noDataFound: 'assets/images/Nodat-found.gif',
  bellIcon: 'assets/images/notification.svg',
  order1: 'assets/images/ord1.svg',
  order2: 'assets/images/ord2.svg',
  order3: 'assets/images/ord3.svg',
  order4: 'assets/images/ord4.svg',
  order5: 'assets/images/ord5.svg',
  order: 'assets/images/order.png',
  packagingPrice: 'assets/images/packaging_price.svg',
  changePasswordSvg: 'assets/images/password.svg',
  payment: 'assets/images/payment.svg',
  placeHolder: 'assets/images/placeholder.png',
  product: 'assets/images/product_n.svg',
  qty: 'assets/images/qty.svg',
  reports: 'assets/images/reports.svg',
  sales: 'assets/images/sales_report.svg',
  setting: 'assets/images/settingss.svg',
  subadmin: 'assets/images/sub_admin.svg',
  totalUser: 'assets/images/total_user.svg',
  uploadDoc: 'assets/images/upload.jpg',
  user_img: 'assets/images/user_img.jpg',
  user: 'assets/images/user.png',
  profileSvg: 'assets/images/user.svg',
  vaector: 'assets/images/vector_bg.png',
  vendor: 'assets/images/vendor_n.svg'
};
