import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { ToasterService } from 'src/app/services/toastr.service';

@Component({
  selector: 'bazaarna-add-notification',
  templateUrl: './add-notification.component.html',
})
export class AddNotificationComponent implements OnInit, OnDestroy {
  submitted = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  addNotificationForm!: FormGroup;
  constructor(
    private notificationSvc: NotificationService,
    public dialogRef: MatDialogRef<AddNotificationComponent>,
    private _toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    this.initializeNotificationForm();
  }

  initializeNotificationForm(): void {
    this.addNotificationForm = new FormGroup({
      notificationSendTo: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      notificationType: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      title: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.maxLength(100)])
      ),
      message: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.maxLength(500)])
      ),
    });
  }

  addNotification(): void {
    this.submitted = true;
    if (this.addNotificationForm.valid) {
      this.notificationSvc
        .addNotification(this.addNotificationForm.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.dialogRef.close({ close: true });
          this._toasterService.successToastr('Notification Added');
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
}
