import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BrandListRespone } from '../models/brandListResponse';
import { Brands } from '../models/brands';
import { ExportCsvResponse } from '../models/exportCsvResponse';
import { MerchantList } from '../models/merhcantList';

@Injectable({
  providedIn: 'root'
})
export class BrandService {
  constructor(private http: HttpClient) {}

  getBrandListing(queryString: string): Observable<BrandListRespone> {
    return this.http
      .get<{ data: BrandListRespone }>(
        `${environment.base_url}admin/getBrand?${queryString}`
      )
      .pipe(map(res => res.data));
  }

  getBrandById(brandId: string): Observable<Brands> {
    return this.http
      .get<{ data: Brands }>(`${environment.base_url}admin/getBrand/${brandId}`)
      .pipe(map(res => res.data));
  }

  getBrandDropDown(merchantId: string): Observable<Brands[]> {
    return this.http
      .get<{ data: Brands[] }>(
        `${environment.base_url}admin/brandDropdown?merchantId:${merchantId}`
      )
      .pipe(map(res => res.data));
  }

  addBrand(payload: Brands): Observable<Brands> {
    return this.http
      .post<{ data: Brands }>(`${environment.base_url}admin/addBrand`, payload)
      .pipe(map(res => res.data));
  }

  updateBrand(payload: Brands, id: string): Observable<Brands> {
    return this.http
      .put<{ data: Brands }>(
        `${environment.base_url}admin/updateBrand/${id}`,
        payload
      )
      .pipe(map(res => res.data));
  }

  updateBrandStatus(
    payload: { isBlocked: boolean },
    id: string
  ): Observable<Brands> {
    return this.http
      .put<{ data: Brands }>(
        `${environment.base_url}admin/updateBrandStatus/${id}`,
        payload
      )
      .pipe(map(res => res.data));
  }

  deleteBrand(data: { deleteBrands: string[] }): Observable<Brands> {
    return this.http
      .delete<{ data: Brands }>(`${environment.base_url}admin/deleteBrand`, {
        body: data
      })
      .pipe(map(res => res.data));
  }

  approveMerchantBrand(
    payload: { isBlocked: boolean },
    id: string
  ): Observable<Brands> {
    return this.http
      .put<{ data: Brands }>(
        `${environment.base_url}admin/approveBrandRequest/${id}`,
        payload
      )
      .pipe(map(res => res.data));
  }

  exportCsv(query: string): Observable<ExportCsvResponse> {
    return this.http.get<ExportCsvResponse>(
      `${environment.base_url}admin/getBrandCsvExport?${query}`
    );
  }

  assignMerchantBrands(
    payload: { merchantArr: Array<string> },
    id: string | string[]
  ): Observable<Brands> {
    return this.http
      .post<{ data: Brands }>(
        `${environment.base_url}admin/assignBrandToMerchant/${id}`,
        payload
      )
      .pipe(map(res => res.data));
  }

  UnAssignMerchantBrands(
    payload: { merchantArr: Array<string> },
    id: string | string[]
  ): Observable<Brands> {
    return this.http
      .post<{ data: Brands }>(
        `${environment.base_url}admin/unAssignBrandFromMerchant/${id}`,
        payload
      )
      .pipe(map(res => res.data));
  }

  getAvailableMerchant(payload: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    brand: string[]| any;
  }): Observable<Array<MerchantList>> {
    return this.http
      .post<{ data: Array<MerchantList> }>(
        `${environment.base_url}admin/merchantDropdownThroughBrand`,
        payload
      )
      .pipe(map(res => res.data));
  }
}
