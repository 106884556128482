import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { LoginBody } from '../models/loginBody';
import { AdminUser } from '../models/adminUser';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { forgotPassword } from '../models/forgotPassword';
import { messageResponse } from '../models/messageResponse';
import { logoutResponse } from '../models/logoutResponse';
import { changePassword } from '../models/changePassword';
import Swal from 'sweetalert2';
import { ResetPassword } from '../models/resetPassword';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  profileDetails: BehaviorSubject<AdminUser | null> =
    new BehaviorSubject<AdminUser | null>(null);
  constructor(private readonly http: HttpClient) {}

  isAuthenticated(): boolean {
    if (
      localStorage.getItem(environment.storageKey) &&
      localStorage.getItem(environment.storageKey) !== null
    ) {
      return true;
    } else {
      return false;
    }
  }

  loginAdmin(payload: LoginBody): Observable<AdminUser> {
    return this.http
      .post<{ data: AdminUser }>(`${environment.base_url}admin/login`, payload)
      .pipe(map(res => res.data));
  }

  forgotPassword(payload: forgotPassword): Observable<messageResponse> {
    return this.http
      .post<{ data: messageResponse }>(
        `${environment.base_url}admin/forgotPassword`,
        payload
      )
      .pipe(map(res => res.data));
  }

  changePassword(payload: changePassword): Observable<messageResponse> {
    return this.http
      .post<{ data: messageResponse }>(
        `${environment.base_url}admin/changePassword`,
        payload
      )
      .pipe(map(res => res.data));
  }

  resetPassword(payload: ResetPassword): Observable<messageResponse> {
    return this.http
      .post<{ data: messageResponse }>(
        `${environment.base_url}admin/resetPassword`,
        payload
      )
      .pipe(map(res => res.data));
  }

  getAdminProfile(): Observable<AdminUser> {
    return this.http
      .get<{ data: AdminUser }>(`${environment.base_url}admin/getProfile`)
      .pipe(map(res => res.data));
  }

  updateAdminProfile(payload: AdminUser): Observable<AdminUser> {
    return this.http
      .put<{ data: AdminUser }>(
        `${environment.base_url}admin/updateProfile`,
        payload
      )
      .pipe(map(res => res.data));
  }

  logout(): Observable<logoutResponse> {
    return this.http.get<logoutResponse>(
      `${environment.base_url}/admin/logout`
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logoutAlert(): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Promise((resolve: any) => {
      Swal.fire({
        title: 'Are You Sure?',
        text: 'You will be logged out!',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        confirmButtonColor: '#3085D6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Logout'
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }).then((result: any) => {
        return resolve(result);
      });
    });
  }
}
