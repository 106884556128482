export const ModuleConst = {
  Dashboard: 1,
  Users: 2,
  Drivers: 3,
  DispatcherManagers: 4,
  CustomTemplate: 5,
  Orders: 6,
  Merchants: 7,
  Reports: 8,
  Categories: 9,
  BrandManagement: 10,
  PackagingPrice: 11,
  Payments: 12,
  Coupon: 13,
  LoyaltyPoints: 14,
  GiftCards: 15,
  AdBanner: 16,
  Notificaton: 17,
  SubAdmin: 18,
  CustomerSupport: 19,
  CmsManagement: 20,
  Logout: 21,
  Setting: 22,
  Attribute: 23
};
