<div class="filter_lft">
  <mat-form-field class="srch" floatLabel="never">
    <button type="button" mat-button matPrefix mat-icon-button>
      <mat-icon *ngIf="!showCrossIcon">search</mat-icon>
      <mat-icon
        style="cursor: pointer"
        *ngIf="showCrossIcon"
        (click)="clearInputField()"
        >close</mat-icon
      >
    </button>
    <input #search matInput placeholder="Search here..." />
  </mat-form-field>
</div>
