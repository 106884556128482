<div class="commission_wrp">
  <h1>{{ (!data ? 'provideCommisiion' : 'payableAmount') | translate }}</h1>
  <form [formGroup]="paymentFormGroup" (ngSubmit)="onSubmit()">
    <div class="form_feilds">
      <div *ngIf="data" class="form_grup w_100">
        <mat-label>
          {{ 'Amount' | translate }}
          <span class="astrick"></span>
        </mat-label>
        <mat-form-field appearance="fill">
          <input
            matInput
            (input)="_couponService.NumOnly($event)"
            (paste)="_couponService.preventPaste($event)"
            [min]="0"
            placeholder="{{ 'Amount' | translate }}"
            formControlName="amount"
            class="form_control" />
        </mat-form-field>
        <mat-error
          *ngIf="
            submitted && paymentFormGroup.get('amount')?.hasError('required')
          "
          >{{ 'Amount' | translate }} {{ 'IsRequired' | translate }}</mat-error
        >
      </div>

      <ng-container *ngIf="!data">
        <div class="form_grup w_100">
          <div>{{ 'merchsntDriver' | translate }}</div>
          <mat-label>
            {{ 'Commissionin%' | translate }}
            <span class="astrick"></span>
          </mat-label>
          <mat-form-field appearance="fill">
            <input
              matInput
              (input)="_couponService.NumOnly($event)"
              (paste)="_couponService.preventPaste($event)"
              [min]="0"
              placeholder="{{ 'Commissionin%' | translate }}"
              formControlName="merchantOwnDriverCommission"
              class="form_control" />
          </mat-form-field>
          <mat-error
            *ngIf="
              submitted &&
              paymentFormGroup
                .get('merchantOwnDriverCommission')
                ?.hasError('required')
            ">
            {{ 'Commission' | translate }}
            {{ 'IsRequired' | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              submitted &&
              paymentFormGroup
                .get('merchantOwnDriverCommission')
                ?.hasError('max')
            ">
            {{ 'provideMaxCommisiion' | translate }}
          </mat-error>
        </div>
        <div class="form_grup w_100">
          <div>{{ 'bazarnaDriver' | translate }}</div>
          <mat-label>
            {{ 'Commissionin%' | translate }}
            <span class="astrick"></span>
          </mat-label>
          <mat-form-field appearance="fill">
            <input
              matInput
              (input)="_couponService.NumOnly($event)"
              (paste)="_couponService.preventPaste($event)"
              [min]="0"
              placeholder="{{ 'Commissionin%' | translate }}"
              formControlName="merchantBazDriverCommission"
              class="form_control" />
          </mat-form-field>
          <mat-error
            *ngIf="
              submitted &&
              paymentFormGroup
                .get('merchantBazDriverCommission')
                ?.hasError('required')
            ">
            {{ 'Commission' | translate }}
            {{ 'IsRequired' | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              submitted &&
              paymentFormGroup
                .get('merchantBazDriverCommission')
                ?.hasError('max')
            ">
            {{ 'provideMaxCommisiion' | translate }}
          </mat-error>
        </div>

        <div class="form_grup w_100">
          <div>{{ 'merchantPichupDriver' | translate }}</div>
          <mat-label>
            {{ 'Commissionin%' | translate }}
            <span class="astrick"></span>
          </mat-label>
          <mat-form-field appearance="fill">
            <input
              matInput
              (input)="_couponService.NumOnly($event)"
              (paste)="_couponService.preventPaste($event)"
              [min]="0"
              placeholder="{{ 'Commissionin%' | translate }}"
              formControlName="adminCommission"
              class="form_control" />
          </mat-form-field>
          <mat-error
            *ngIf="
              submitted &&
              paymentFormGroup.get('adminCommission')?.hasError('required')
            ">
            {{ 'Commission' | translate }}
            {{ 'IsRequired' | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              submitted &&
              paymentFormGroup.get('adminCommission')?.hasError('max')
            ">
            {{ 'provideMaxCommisiion' | translate }}
          </mat-error>
        </div>
      </ng-container>

      <div class="btn_group">
        <button class="btn btn-primary" type="submit">
          {{ (!data ? 'Save' : 'Pay') | translate }}
        </button>

        <button class="btn btn-primary" type="button" (click)="closeDialog()">
          {{ 'Close' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
